import { createTheme } from "@mui/material/styles";
import typography from "./Typography";
import { shadows } from "./Shadows";

const baselightTheme = createTheme({
  direction: 'ltr',
  palette: {
    primary: {
      main: '#5D87FF',
      light: '#ECF2FF',
      dark: '#4570EA',
    },
    secondary: {
      main: '#49BEFF',
      light: '#E8F7FF',
      dark: '#23afdb',
    },
    lush: {
      main: '#060d2e',
      light: '#ededed',
      veryLight: '#fafafa',
      dark: '#2b2fff',
      contrastText: '#ffffff',
    },
    lushMain: {
      main: '#13DEB9',
      light: '#E6FFFA',
      dark: '#02b3a9',
      contrastText: '#ffffff',
    },
    lushBright: {
      100: '#e8fff8',
      200: '#cefcef',
      300: '#bcfdea',
      400: '#a3fee4',
      500: '#8ffede',
      600: '#78ffd8',
      700: '#5ffed0',
      800: '#44fec9',
      900: '#26ffc0',
      1000: '#0dffb9'
    },
    lushMed: {
      100: '#a8b9b4',
      200: '#99bbb1',
      300: '#88b6a9',
      400: '#79bda9',
      500: '#66b49e',
      600: '#55b599',
      700: '#41ae8e',
      800: '#2faf8b',
      900: '#1bb487',
      1000: '#09b281'
    },
    lushDark: {
      100: '#6b7673',
      200: '#688079',
      300: '#58766d',
      400: '#518072',
      500: '#44786a',
      600: '#357361',
      700: '#276855',
      800: '#1d6d57',
      900: '#0f6e52',
      1000: '#066c4e'
    },
    success: {
      main: '#0a911b',
      light: '#e6ffe8',
      dark: '#018511',
      contrastText: '#ffffff',
    },
    info: {
      main: '#539BFF',
      light: '#EBF3FE',
      dark: '#1682d4',
      contrastText: '#ffffff',
    },
    error: {
      main: '#FA896B',
      light: '#FDEDE8',
      dark: '#f3704d',
      contrastText: '#ffffff',
    },
    warning: {
      main: '#FFAE1F',
      light: '#FEF5E5',
      dark: '#ae8e59',
      contrastText: '#ffffff',
    },
    purple: {
      A50: '#EBF3FE',
      A100: '#6610f2',
      A200: '#557fb9',
    },
    grey: {
      100: '#F2F6FA',
      200: '#EAEFF4',
      300: '#DFE5EF',
      400: '#7C8FAC',
      500: '#5A6A85',
      600: '#2A3547',

    },
    text: {
      main: "#000000",
    },
    action: {
      disabledBackground: 'rgba(73,82,88,0.12)',
      hoverOpacity: 0.02,
      hover: '#f6f9fc',
    },
    divider: '#e5eaef',
  },
  typography,
  shadows
},
);

export { baselightTheme };
