import {
  fixExtraneousWhitespace
} from './ocr_post_processing.js';

const status_set = ['unpaid', 'paid', 'pending', 'unknown'];
const currency_set = [
  { locale: 'eu', symbol: '€', code: 'EUR' },
  { locale: 'us', symbol: '$', code: 'USD' }
]

/**
 * 
 * @param {*} sb  symbol - either a currency symbol or a currency code 
 */
const toCurrencyCode = (sb) => {
  const idx = currency_set.reduce((acc, x, i) => acc !== -1 || (sb !== x['code'] && sb !== x['symbol']) ? acc : i, -1);
  if (idx == -1) {
    throw new Error(`no such currency symbol or currency code: ${sb}`);
  }
  return currency_set[idx]['code'];
}
const regClean = (v) => ['^', '$', '*', '?'].reduce((acc, l) => acc.replace(l, `[${l}]`), v);
const regexify = (x) => Object.keys(x).reduce((acc, k) => ({ ...acc, [k]: regClean(x[k]) }), {});

/**
 * 
 * @param {*} x 
 * @returns {
 * { currency: string | null , amount: Number }[] 
 * } amount is given in ct (ie. amount / 100 provides the amount in [currency])
 */
const extractAmountCurrency = (x) => {
  const syms = currency_set.map((st) => regexify(st)).map(({ symbol, code }) => `(?:${symbol})|(?:${code})`).join('|');
  const decimal_dot = '(?<dec_dot>[0-9]+(?:[,][0-9]{3})*[.][0-9]{2})';
  const decimal_comma = '(?<dec_comma>[0-9]+(?:[.][0-9]{3})*[,][0-9]{2})';
  const make_cur = (group = undefined) => '[(]?' + (!!group ? `(?<${group}>${syms})` : syms) + '[)]?';

  const makeAmount = (x, decSep, vizSep) => {
    if (typeof x !== 'string') {
      throw Error(`'x' has tb of type string. Is ${typeof x}.`);
    }
    if (!(
      (
        decSep === '.' && vizSep === ','
      ) || (
        decSep === ',' && vizSep === '.'
      )
    )) {
      throw Error(`'decSep' and 'vizSep': decSep == '.' && vizSep == ',' ||  decSep == ',' && vizSep == '.' - are decSep=${decSep} vizSep=${vizSep}`);
    }
    const splts = x.split(decSep);

    if (splts.length !== 2) {
      throw new Error("splitting 'x' on 'decSep' has to split string into integer and decimal part.")
    }
    const [int, dec] = splts;
    const amt = {
      decimal: Number(dec),
      integer: Number(int.replaceAll(vizSep, ""))
    };
    
    const toDecimalDotString = () => `${amt.integer}.${''.padStart(2, amt.decimal)}`;

    return ({
      value: { ...amt },
      toDecimalDotString,
      toDecimalCommaString: () => `${amt.integer},${''.padStart(2, amt.decimal)}`,
      toDecimal: () => Number(toDecimalDotString()),
    });
  }

  return [...x.matchAll(
    `(?:(?:(?:(?=${make_cur()})(?:${make_cur('cur_pre')}))?\\s*(?<=[^0-9.,]))|^)(?:${decimal_comma}|${decimal_dot})(?:$|(?:(?=[^0-9,.]+)\\s*(?:${make_cur('cur_post')})?))`
  )].filter((mt) => mt !== null).map((mt) => {
    return ({
      currency: mt.groups.cur_pre === undefined ? (mt.groups.cur_post === undefined ? null : toCurrencyCode(mt.groups.cur_post)) : toCurrencyCode(mt.groups.cur_pre),
      amount: mt.groups.dec_dot !== undefined ? makeAmount(
        mt.groups.dec_dot,
        ".",
        ","
      ) : mt.groups.dec_comma !== undefined ? makeAmount(
        mt.groups.dec_comma,
        ",",
        "."
      ) : null,
      extractedText: mt[0]
    });
  });
}


// should be all lower case
const localeMonths = {
  'en': {
    'full': ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'],
    'abbrev': ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec']
  }
}
const localeCardinals = {
  'en': ['st', 'nd', 'rd', 'th']
}
const localMonth = (lang) => Object.keys(localeMonths).includes(lang) ?
  ([...localeMonths[lang]['full'], ...localeMonths[lang]['abbrev']]).map((l) => '(?:' + l + ')').join('|')
  : null;
const localCardinal = (lang) => Object.keys(localeCardinals).includes(lang) ? '(?:' + localeCardinals[lang].join(')|(?:') + ')' : null;

const localPostPrep = (lang) => {
  switch (lang) {
    case 'en': return ['of'];
    default: return null;
  }
}

const createDateRegex = (lang) => {
  const sep = ['[/]', '[.]', '-', ' '];
  const textSep = [',', ' '];
  const dm = '[0-9]{1,2}';
  const yearShort = '[0-9]{2}'
  const yearLong = '[0-9]{4}'

  const year = '(?:' + yearLong + '|' + yearShort + ')';
  const lM = localMonth(lang);
  const lC = localCardinal(lang);
  const lPP = localPostPrep(lang);

  const m_pre = [dm, `(?:${lC})?`, `(?:${lPP})?`, lM];
  const m_post = [lM, dm, `(?:${lC})?`];

  const mapToLabel = (v) => {
    switch (v) {
      case dm: return 'dm';
      case year: return 'year';
      case yearLong: return 'year';
      case yearShort: return 'year';
      case lM: return 'localMonth';
      default:
        return null;
    }
  }

  const configurations = [

    // TODO: figure out why the order of configurations matters. 
    // obviously in a regex the first expression matching will be consumed
    // for some reason although textSep and sep regexpressions differ 
    // one has localMonth the other does not - which should prevent mutual matches
    // the order still matters




    // year last

    // general date
    ...sep.map((sp) => [dm, sp, dm, sp, year]),

    // textual - language-specific
    ...textSep.map((tsp) => [...m_pre, tsp, year]),
    ...textSep.map((tsp) => [...m_post, tsp, year]),



    // year first

    // general date
    ...sep.map((sp) => [yearLong, sp, dm, sp, dm]),

    // textual
    ...textSep.map((tsp) => [yearLong, tsp, ...m_pre]),
    ...textSep.map((tsp) => [yearLong, tsp, ...m_post]),

    // [year, textSep.join("|"), ...m_pre],
    // [year, textSep.join("|"), ...m_post],
    // [...m_pre, textSep.join("|"), year],
    // [...m_post, textSep.join("|"), year],

    // INCOMPLETE
    // incomplete - missing year
    // [dm, sep.join("|"), dm],
    // incomplete - missing day
    // [dm, sep.join("|"), year],
    // [year, sep.join("|"), dm],
  ];
  // allow for arbitrary white space between elements of a configuration
  // and capture any of the configurations
  const confExp = configurations.map((conf, i) => conf.map((c, j) => {
    const lbl = mapToLabel(c);
    // return lbl === null ? `(?:${c})` : `(?<${lbl}_${i}${lbl == "dm" && conf.slice(0,j).includes(c) ? "_1" : ""}>${c})`
    return lbl === null ? `(?:${c})` : `(?<${lbl}_${i}_${j}>${c})`
  }).join('(?:\\s+)?'));

  // sort, reverse ensures that the longest most specific regex come first
  // however note that regex semantics (esp. quantifiers) are not considered eg (?:...)? optional groups
  return '(?:' + confExp.sort().reverse().join(')|(?:') + ')';
}

/**
 * 
 * @param {string} x    string from which dates are tb extracted 
 * @param {string} lang language used for locale literals
 * @param {string} dmOrderStandard orderStandard in {european, american} to resolve whether the first mention (dm_0) 
 *     is resolved to month (american) or day (european)
 * @param {Number} referenceYear year used as reference for resolution (eg. when year specified in YY format or when year not specified eg. DD/MM)
 * @returns {{
 *  dates: Date[]
 * _extractions: { dm_0: string, dm_1: string, year: string}[],
 * _params: { lang: string, dmOrderStandard: string, referenceYear: Number }
 * }} resolved dates
 */
const extractDates = (x, lang, referenceYear, dmOrderStandard = 'american') => {

  const dmOrderStandards = ['european', 'american'];
  if (!dmOrderStandards.includes(dmOrderStandard)) {
    throw new Error(`'dmOrderStandard' has tb in ${dmOrderStandards}`);
  }

  /**
   * 
   * { dm_0: '11', localMonth: 'Jan', year: '2024' }
   * { dm_0: '12', dm_1: '12', year: '10' }
   */

  const dates = [...x.toLowerCase().matchAll(createDateRegex(lang))].filter(
    (mt) => mt !== null
  ).map(
    (mt) => {
      const txt = mt[0];
      const gr = mt.groups;
      const [d, _] = Object.keys(gr).reduce(([acc, dm_idx], k) => {
        const mt = k.match(/(?<key>[^0-9 _]+)_(?<i>[0-9]+)_(?<j>[0-9]+)/);
        const kg = mt.groups;
        return k === 'text' || gr[k] === undefined ? [acc, dm_idx] : [(
          { ...acc, [kg['key'] + (kg['key'] == 'dm' ? `_${dm_idx++}` : '')]: gr[k] }
          // k.slice(0,-'_0_0'.length) + (k.startsWith('dm') ? k.slice(-2): '')
        ), dm_idx];
      }, [{}, 0]);
      const res = { year: Object.keys(d).includes('year') ? Number(d['year']) : referenceYear };
      if (Object.keys(d).includes('localMonth')) {
        // 1-indexed -> [1,12] tb consistent with day format
        res['month'] = ([...localeMonths[lang]['full'], ...localeMonths[lang]['abbrev']].indexOf(d['localMonth']) % 12 + 1);
        res['day'] = Number(d['dm_0']);
      } else if (d['dm_0'] > 12) {
        res['month'] = Number(d['dm_1']);
        res['day'] = Number(d['dm_0']);
      } else if (d['dm_1'] > 12) {
        res['month'] = Number(d['dm_0']);
        res['day'] = Number(d['dm_1']);
      } else {
        // resolve according to dm ordering standard
        if (dmOrderStandard === 'american') {
          res['month'] = Number(d['dm_0']);
          res['day'] = Number(d['dm_1']);
        } else {
          // european
          res['month'] = Number(d['dm_1']);
          res['day'] = Number(d['dm_0']);
        }
      }
      // requires month as index (0-indexed)
      return ({
        date: new Date(res['year'] + (Object.keys(d).includes('year') && d['year'].length === 2 ? Math.round(referenceYear / 100) * 100 : 0), res['month'] - 1, res['day']),
        extraction: d,
        extractedText: txt
      });
    }
  );

  return { dates: dates, _params: { lang, dmOrderStandard, referenceYear } };
}


/**
 * 
 * @param {*} x 
 * @returns {string[]}
 */
const extractInvoiceNumbers = (x) => [...x.matchAll(/[0-9A-Za-z_-]{6,}/g)].filter(
  (m) => m !== null && m[0].match(/[0-9]+/) !== null
).map(
  (m) => ({ value: m[0], extractedText: m[0] })
);

/**
 * 
 * @param {string} type 
 * @param {string} text 
 * @returns array of extractions from parameter 'text' matching type 'type'
 */
const extractValue = (type, text) => {
  let v = [];
  switch (type) {
    case 'date':
      v = extractDates(text, 'en', 2024).dates;
      break;
    case 'amount_cur':
      v = extractAmountCurrency(text);
      break;
    case 'invoice_no':
      v = extractInvoiceNumbers(text);
      break;
    default:
      throw new Error("'type' not supported");
  }
  return v;
}

const extractValues = (fullText, schema) => {

  return Object.keys(schema).reduce((acc, k) => {
    return ({ ...acc, [k]: extractValue(schema[k]['type'], fullText) });
    // let v;
    // switch (schema[k]['type']) {
    //   case 'date':
    //     v = extractDates(fullText, 'en', 2024).dates;
    //     break;
    //   case 'amount_cur':
    //     v = extractAmountCurrency(fullText);
    //     break;
    //   case 'invoice_no':
    //     v = extractInvoiceNumbers(fullText);
    //     break;
    //   default:
    //     throw new Error("'type' not supported");
    // }
    // return ({ ...acc, [k]: v });
  }, {});
}


// const invoiceSchema = {
//   'invoice_date': 'date',
//   'due_date': 'date',
//   'due_interval': 'date',
//   'invoice_no': 'invoice_no',
//   'total': 'amount_cur',
//   'amount_due': 'amount_cur'
//   // 'status': 'status'
// };

/**
 * may require resolution of multiple options found 
 * - prefer matches with lower index: in order of descending specificity
 */
// const invoiceKeyIndicatorSchema = {
//   'invoice_date': ['invoice date', 'date'],
//   'due_date': ['due date', 'date'],
//   'invoice_no': ['invoice number', 'invoice no.', 'invoice no', 'invoice id'],
//   'total': ['total'],
//   'amount_due': ['amount due', 'due']
// }



const invoiceSchema = {
  'INVOICE_CREATION_DATE': { 'type': 'date', 'keys': ['invoice date', 'date of issue', 'date'] },
  'DUE_DATE': { 'type': 'date', 'keys': ['due date', 'date due', 'date'] },
  'INVOICE_NUMBER': { 'type': 'invoice_no', 'keys': ['invoice number', 'invoice no.', 'invoice no', 'invoice id', 'invoice #', 'invoice#'] },
  'AMOUNT_TOTAL': { 'type': 'amount_cur', 'keys': ['total'] },
  'AMOUNT_DUE': { 'type': 'amount_cur', 'keys': ['amount due', 'due'] }
  // 'status': 'status'
};



const extractKeys = (fullText, schema) => {
  const extractions = Object.keys(schema).reduce((acc, k) => {
    let indices = null;
    let option_idx = 0;

    const keys = schema[k]['keys'];
    // attempts to match options for a given key in schema in order of ascending indices
    // - once matches are found for a given option schema[k][option_idx] returns
    while (indices === null && option_idx < keys.length) {
      const option = keys[option_idx];
      const extractedIndices = [...fullText.matchAll(option)].reduce((acc, k) => [...acc, k.index], []);
      if (extractedIndices.length > 0) {
        indices = { option, indices: extractedIndices };
      }
      option_idx += 1;
    }
    return ({ ...acc, [k]: indices !== null ? indices : { option: null, indices: [] } });
  }, {});

  return extractions;
}




// /**
//  * 
//  * @param {string} text 
//  * @returns 
//  */
// const findKeysInText = (text) => {
//   const cand = text.toLowerCase();
//   return Object.keys(invoiceKeyIndicatorSchema).reduce((acc, k) => ({ 
//     ...acc, 
//     [k]: invoiceKeyIndicatorSchema[k].map((e,i) => e.startsWith(cand) ? i : -1).filter(e => e !== -1) 
//   }), {});
// } 

const checkContext = (text, line, blockText) => {
  const cand = text.toLowerCase();
  const candLine = line.toLowerCase();
  const candBlock = blockText.toLowerCase();

  const lineIdxInBlock = candBlock.indexOf(candLine);
  const wordIdxInLine = candLine.indexOf(cand);
  const wordIdxInBlock = lineIdxInBlock + wordIdxInLine;

  return {
    'lineIdxInBlock': [lineIdxInBlock, lineIdxInBlock + candLine.length],
    'wordIdxInLine': [wordIdxInLine, wordIdxInLine + cand.length],
    'wordIdxInBlock': [wordIdxInBlock, wordIdxInBlock + cand.length],
  };
}

/**
 * 
 * @param {{
 * words: { 
 *  text: string,
 *  line: { text: string, bbox: number[] },
 *  block: { text: string, bbox: number[] }, 
 * }[],
 * blocks: { text: string }[]
 * }} recognition 
* @returns {{
* cleanBlock: string,
* values: {
*   [k: string]: [number,number] 
*  }
* }} obj
* - cleanBlock: block with contiguous whitespace replace by single ' '
* - values: mapping of field keys to start and stop idx in cleanBlock
*/
const findValues = (recognition) => extractValues(recognition, invoiceSchema);


/**
 * match length (number of matching chars) between key and extracted word
 * @param {string} key           
 * @param {string} extractedWord 
 * @returns {Number} match length (number of matching chars) between key and extracted word
 */
const matchLength = (key, extractedWord) => [...extractedWord].reduce((acc, _, i) => key[i] === extractedWord[i] ? acc + 1 : acc, 0);


/**
 * check whether the extracted word is  a close match for expression
 * @param {string} expression           expected expression  
 * @param {string} extractedWord        extracted word
 * @param {Number} flipTolerance        percentage of char flips allowed 
 *                                      - number of char flips is computed as the ceiling  
 */
const closeMatch = (expression, extractedWord, flipTolerance) => {
  if (typeof flipTolerance !== 'number' || flipTolerance < 0.0 || flipTolerance > 1.0){
    throw new Error(`'flipTolerance' has tb a number in [0.0,1.0] - is ${flipTolerance}`)
  }
  const res = expression.length === extractedWord.length
    && expression.length - matchLength(expression, extractedWord) <= Math.floor(extractedWord.length * flipTolerance);
  return res;
}


/**
 * convert a set of word instances to string
 * @param {{
 *  blockIndex: Number, 
 *  paragraphIndex: Number,
 *  lineIndex: Number, 
 *  wordIndex: Number,
 *  word: string,
 *  processedWord: string,
 * }[]} wordIndices
 * @param {string} wordSep string inserted to separate words if no line break detected to previous word (ie. no diff in y0) 
 * @param {string} lineSep string inserted to separate lines ~ separates words if line break detected in consecutive word instances (ie diff in y0 > 0 )
 * @returns 
 */

/**
 * 
 * @param {*} wordIndices sorted top to bottom first, then left to right
 * @param {*} wordSep 
 * @param {*} lineSep 
 * @param {Number} tolerance in pixels - used to determine whether the difference in y between two words merits a line separation
 * @returns 
 */
const wordIndicesToString = (wordIndices, wordSep=' ', lineSep='\n', tolerance=1) => 
  wordIndices.reduce((acc, v,i) => [...acc, ...(
    i > 0 ? (
      isClose([wordIndices[i-1].bbox.y0, wordIndices[i-1].bbox.y1], [v.bbox.y0, v.bbox.y1], 'any', tolerance) ? [lineSep] : [wordSep]
    ): []
  ), v.processedWord], []).join('')

/**
 * order extracted words by their bbox in top to bottom first and left to right second (tblr)
 * @param {*} extraction 
 * @param {function} preprocessing
 * @returns {{
*  blockIndex: Number, 
*  paragraphIndex: Number,
*  lineIndex: Number, 
*  wordIndex: Number,
*  word: string,
*  processedWord: string,
* }[]} array of word instances ordered by their bbox in lrtb fashion
*/
const orderExtractedWordsByBbox = (extraction, preprocessing) => {
  if (typeof preprocessing !== 'function' || preprocessing.length !== 1) {
    throw new Error("'preprocessing' has tb a function of argument length 1.");
  }

  let wordIndices = [];

  // NOTE: order of items in blocks, paragraphs, lines returned by OCR is not guaranteed 
  // and should be ignored - instead make use of bbox to determine word order
  // - unfortunately if the 'western layout' (left->right then top->bottom - 'lrtb') for reading order is not adhered to the 
  //   ordering may fail 
  extraction.blocks.forEach((block, blockIndex) => {
    block.paragraphs.forEach((paragraph, paragraphIndex) => {
      paragraph.lines.forEach((line, lineIndex) => {
        line.words.forEach((word, wordIndex) => {
          const processedWord = preprocessing(word.text);
          wordIndices.push({
            bbox: word.bbox,
            word,
            processedWord,
          });
        });
      });
    });
  });

  // @later: modularize to support different layouts (current:'lrtb')
  return wordIndices.sort((a, b) => 
    ! isClose([a.bbox.y0, a.bbox.y1], [b.bbox.y0, b.bbox.y1], 'any') ? modeMin([a.bbox.y0, a.bbox.y1], [b.bbox.y0, b.bbox.y1], 'any') : modeMin([a.bbox.x0, a.bbox.x1], [b.bbox.x0, b.bbox.x1], 'any') 
    // a.bbox.y0 - b.bbox.y0 !== 0 ? a.bbox.y0 - b.bbox.y0 : a.bbox.x0 - b.bbox.x0
  );
}


/**
 * find the set of word-level extractions corresponding to each match,
 * computed previously against the aggregated string 
 * @param {{
 *  blockIndex: Number, 
*  paragraphIndex: Number,
*  lineIndex: Number, 
*  wordIndex: Number,
*  word: string,
*  processedWord: string,
* }[]} wordIndices 
 * @param {string[]} matches 
 * @param {Number} flipTolerance      percentage of character flips tolerated for matching
 * @returns {{
 *  blockIndex: Number, 
 *  paragraphIndex: Number,
 *  lineIndex: Number, 
 *  wordIndex: Number,
 *  word: string,
 *  processedWord: string,
 * }[][]}
 */
const findWordExtraction = (wordIndices, matches, flipTolerance = 0.25) => {
  if (!Array.isArray(matches) || matches.reduce((acc, mt) => acc || typeof mt !== 'string', false)) {
    throw new Error("'matches' has tb of type string[]");
  }

  const extractions = [];


  // blocks > paragraphs > lines > words
  matches.forEach((mt) => {
    const extractionCandidates = [];
    let runningSequence = [];

    const startCandidateIdx = wordIndices.reduce((acc, word, i) => closeMatch(
      mt.slice(0, word.processedWord.length),
      word.processedWord,
      flipTolerance
    ) ? [...acc, i] : acc, []);
    
    for (let idx of startCandidateIdx) {
      for (let word of wordIndices.slice(idx)) {
        // use same sep literal for word and line separation
        const currentSeq = wordIndicesToString(runningSequence, ' ', ' ');
        const offset = currentSeq.length + (mt[currentSeq.length] === ' ' ? 1 : 0);
        if (
          closeMatch(
            mt.slice(offset, offset + word.processedWord.length),
            word.processedWord,
            flipTolerance
          )
        ) {
          runningSequence.push(word);
        } else {
          break;
        }
        if (
          closeMatch(
            wordIndicesToString(runningSequence, ' ', ' '),
            mt,
            flipTolerance
          )
        ) {
          extractionCandidates.push(runningSequence);
          break;
        }
      }
      runningSequence = [];
    }

    const extr = extractionCandidates.map((v) => matchLength(mt, wordIndicesToString(v))).reduce(
      (acc, v, i) => v > acc[0] ? [v, i] : acc,
      [-1, -1]
    );

    if (extr[1] !== -1) {
      extractions.push(extractionCandidates[extr[1]]);
    }
  });

  return extractions;
};


/**
 * euclidean distance metric (L2 norm)
 * @param {{
 *   x: Number,
 *   y: Number
 * }} distance 2d vector representing the distance between two points 
 * @returns scalar distance score 
 */
const euclidean = ({ x, y }) => Math.sqrt(x**2 + y**2);


/**
 * distance metric that prefers top to bottom left to right 
 * ie.
 * - y axis over x axis 
 * - direction top to bottom and left to right respectively
 * 
 * @param {{
 *   x: Number,
 *   y: Number
 * }} distance 2d vector representing the distance between two points 
 * @returns scalar distance score 
 */
const distanceMetricTBLR = ({ x, y }) => {
  // penalize direction (x < 0) means key is found after value
  // key after value in x and y or before with at least one line separating them 
  if (x < 0 && y <= 0 || y < -1) {
      // offset of 100.0 pixels to ensure very small deviations are not considered preferably
      return 100.0 + euclidean({ x: (x < 0 ? x * 3 : x ), y: (y < 0 ? y * 3 : y ) });
  }
  // prefer distance in x direction over y -> use a multiple in y
  return euclidean({ x, y: y*2 });
}

/**
 * extract pairs of KV based on schema from OCR extraction
 * @param {*} extraction 
 * @param {{ [k:string]: string }} schema 
 * @param {(x:{ x: Number, y: Number }) => Number} d distance metric to compute distance between key and value candidates
 * @param {boolean} ignore_case - whether to ignore case when matching against keys and values
 * @returns 
 */
const extractBySchema = (extraction, schema, d, ignore_case = true) => {
  
  let preprocessing = fixExtraneousWhitespace;
  if (ignore_case) {
    preprocessing = (s) => fixExtraneousWhitespace(s).toLowerCase();
  }

  const wordIndices = orderExtractedWordsByBbox(extraction, preprocessing);
  // use same sep literal for word and line separation
  const processedText = wordIndicesToString(wordIndices, ' ', ' ');
  
  let keys = extractKeys(processedText, schema);
  
  let values = extractValues(processedText, schema);

  // match ignoring any kind of whitespace
  const clean = (x, preprocessing) => x === undefined || typeof x !== 'string' ? undefined : x.replaceAll(/\s+/g, ' ').replaceAll(/(?:(?:^\s+)|(?:\s+$))/g, '').split(' ').map((w) => preprocessing(w)).join(' ');
  // make unique as kv pair with the smallest distance among a set of key pairs and value pairs is returned 
  // therefore redundant words do not affect the result
  // - note that this is necessary for values and not for keys
  // - as keys always extracts a single match based on the preferrred key list and does not return several in case of several matches for a given kw
  const uniqueValues = (vals) => 
    Object.keys(vals).reduce((acc, k) => ({ ...acc, [k]: [...new Set(vals[k])] })
    , {});
  const updateKeys = (valueMapping, preprocessing) => Object.keys(valueMapping).reduce((acc, k) => ({ ...acc, [k]: { ...valueMapping[k], 'option': clean(valueMapping[k]['option'], preprocessing) }}), {});
  const updateValues = (valueMapping,preprocessing, unpack=true) => Object.keys(valueMapping).reduce((acc, k) => ({ ...acc, [k]: valueMapping[k].map((v) => unpack ? clean(v['extractedText'], preprocessing) : ({ ...v, extractedText: clean(v['extractedText'], preprocessing) }) ) }), {});
  keys = updateKeys(keys, preprocessing); 
  values = updateValues(values, preprocessing);
  values = uniqueValues(values);
  
  keys = Object.keys(keys).reduce((acc, k) => ({
    ...acc,
    [k]: !keys[k]['option'] ? [] : [keys[k]['option']]
  }), {});


  let kvPairCandidates = Object.keys(schema).reduce((acc, k) => {
    const keyMatches = keys[k];
    const valueMatches = values[k];

    const keyCandidates = findWordExtraction(wordIndices, keyMatches, 0.0);
    const valueCandidates = findWordExtraction(wordIndices, valueMatches, 0.0);

    // only if both are found
    const kvPairs = keyCandidates.length > 0 && valueCandidates.length > 0 ? findKVPair(keyCandidates, valueCandidates, d) : [];


    return [
      ...acc,
      ...kvPairs.map(({ keyIndex, valueIndex, distanceDetails }) => ({ 
        schemaKey: k,
        key: keyCandidates[keyIndex],
        value: valueCandidates[valueIndex],
        keyIndex, 
        valueIndex, 
        distanceDetails
       }))
    ]; 
  }, []);


    // for each schema key determine the minimal distance value pair 
    // - if the value is used in another minimal distance kv pair of another schema key
    //   then bind it to the schema key that has the smaller kv pair distance 
    //    and remove it from the other schema key's candidate list and recompute
    // note that keys are assumed to be unique for schema keys ie. the same key ma ynever be associated with two schema keys

    const computeMinimalKeyIndicesBySchemaKey = (pairs) => [...new Set(pairs.map((v) => v.schemaKey))].reduce((acc, k) => ({ ...acc, [k]: pairs.reduce((acc, v, i) => 
        v.schemaKey === k && (acc[0] === -1 || acc[0] > v.distanceDetails.distance ) ? [v.distanceDetails.distance, i] : acc, 
        [-1,-1]
      )[1] }), {});

    // something fails here 
    const computeDuplicates = (pairs, minIndices) => Object.values(minIndices).reduce(
      (acc, mi) => [...acc, Object.keys(minIndices).reduce(
        (accc, k) => pairs[minIndices[k]].value === pairs[mi].value ? ({ ...accc, [k]: minIndices[k] }) : accc, 
        {}
      )], 
      []
    // only retain such value indices that match several pair indices
    ).filter((v) => Object.keys(v).length > 1);

    const computeMinimalDistanceKey = (pairs, duplicates) => duplicates.map((dupl) => {
      return Object.keys(dupl).reduce(
        (acc, k) => acc['distanceDetails']['distance'] === -1 || pairs[dupl[k]]['distanceDetails']['distance'] < acc['distanceDetails']['distance'] ? ({...pairs[dupl[k]], key: k, pairIndex: dupl[k], dupes: [...acc.dupes, ...(acc.pairIndex !== -1 ? [acc.pairIndex] : []) ] }) : acc
      );
    }, { 'distanceDetails': { 'distance': -1 }, dupes: [], pairIndex: -1 });




    let minIndices = computeMinimalKeyIndicesBySchemaKey(kvPairCandidates);
    
    
    let dupes = computeDuplicates(kvPairCandidates, minIndices);

    while(dupes.length > 0){
      const closest = computeMinimalDistanceKey(kvPairCandidates, dupes);
      const indicesTbDeleted = closest.reduce((acc, v) => [...acc, ...v.dupes], []);
      kvPairCandidates = kvPairCandidates.filter((p,i) => ! indicesTbDeleted.includes(i));
      minIndices = computeMinimalKeyIndicesBySchemaKey(kvPairCandidates);
      dupes = computeDuplicates(kvPairCandidates, minIndices);

    }
    
    
    const schemaPairs = Object.keys(minIndices).reduce((acc, k) => ({
      ...acc,
      [k]: kvPairCandidates[minIndices[k]]
    }), {});
    return schemaPairs;
};

/**
 * returns the minimum distance for one dimension of a bounding box (start, end) across modes included 
 * in the alignment mode
 * @param {[Number,Number]} a (start,end) of bbox a in some dimension (eg. x) 
 * @param {[Number,Number]} b (start,end) of bbox b in some dimension (eg. x)
 * @param {string} alignMode mode of alignment to be used for comparison
 * @returns minimum distance in dimension between the two bounding boxes,
 * where minimum is determined by the absolute value yet the returned value is directed
 * (ie. < 0 if b > a)
 */
const modeMin = (a,b,alignMode) => {
  const modes = ['top', 'bottom', 'center', 'any'];
  if (! modes.includes(alignMode)){
    throw new Error(`'alignMode' has tb in ${modes.join(',')}`);
  }
  if (a.length !== 2 || b.length !== 2){
    throw new Error("'a' and 'b' have to be of length 2")
  }

  return [
    ...(
      !['top', 'any'].includes(alignMode) ? [] : [a[0]-b[0]]
    ), 
    ...(
      !['bottom', 'any'].includes(alignMode) ? [] : [a[1]-b[1]]
    ),
    ...(
      !['center', 'any'].includes(alignMode) ? [] : [((a[1]-a[0])/2 + a[0]) - ((b[1]-b[0])/2 + b[0])]
    )
  ].reduce((acc, v) => acc === null || Math.abs(v) < Math.abs(acc) ? v : acc, null);
};

/**
 * compare whether two bboxes along a specific dimension are close based on an alignment mode
 * @param {[Number,Number]} a (start,end) of bbox a in some dimension (eg. x) 
 * @param {[Number,Number]} b (start,end) of bbox b in some dimension (eg. x)
 * @param {string} alignMode mode of alignment to be used for comparison
 * @param {Number} thresh inklusive threshold for tolerated distance
 * @returns 
 */
const isClose = (a, b, alignMode, thresh=1) => Math.abs(modeMin(a,b,alignMode)) <= thresh;

/**
 * whether words are contiguous in x and y 
 * @param {{
*   text: string,
*   bbox: { x0: Number, y0: Number, x1: Number, y1: Number },
*   direction: string, 
*   fontsize: Number,
* }[]} words 
* @param {number} threshold
*/
const isContiguous = (words, threshX, threshY) => {
  return words.length > 0
    && words.reduce((acc, w) => acc
      && isClose([w.bbox.y0, w.bbox.y1], [words[0].bbox.y0, words[0].bbox.y1], 'any', threshY),
      true
    ) && [...Array(words.length - 1).keys()]
    .map((idx) =>
      Math.abs(words[idx].bbox.x1 - words[idx + 1].bbox.x0)
    ).filter((dist) => 
      dist < threshX
    ).length === words.length - 1;
};

/**
* compute center of a given bbox
* @param {{ x0: Number, x1: Number, y0: Number, y1: Number }} bbox 
* @returns 
*/
const computeBBoxCenter = (bbox) => ({
  x: (bbox.x1 - bbox.x0) / 2 + bbox.x0,
  y: (bbox.y1 - bbox.y0) / 2 + bbox.y0
});

/**
* find the kv pair with the smallest distance among a set of key pairs and value pairs
* 
* @param {{
*   text: string,
*   bbox: { x0: Number, y0: Number, x1: Number, y1: Number },
*   direction: string,  
*   fontsize: Number,
* }[]} keyWords
* @param {{
*   text: string,
*   bbox: { x0: Number, y0: Number, x1: Number, y1: Number },
*   direction: string, 
*   fontsize: Number,
* }[]} valueWords 
* @param {(dist: { x: Number, y: Number }) => Number} d
* @returns {{ referencePoints: { key: { x: Number, y: Number }, value: { x: Number, y: Number } }, distance: { x: Number, y: Number } }}
*/
const computeKVDistance = (keyWords, valueWords, d) => {

  if (
    keyWords.length === 0
    || valueWords.length === 0
  ) {
    throw new Error("'keyWords' and 'valueWords' have tb of the same length and >= 1.");
  }
  if (typeof d !== 'function' || d.length !== 1) {
    throw new Error("'d' has tb a function taking 1 argument ({x, y}).");
  }

  let keyPoint = undefined;
  let valuePoint = undefined;
  let distance = undefined;

  if (isContiguous(keyWords, 5.0, 5.0)) {
    keyPoint = computeBBoxCenter({
      x0: keyWords[0].bbox.x0,
      x1: keyWords[keyWords.length - 1].bbox.x1,
      y0: keyWords[0].bbox.y0,
      y1: keyWords[keyWords.length - 1].bbox.y1
    });
  }

  if (isContiguous(valueWords, 5.0, 5.0)) {
    valuePoint = computeBBoxCenter({
      x0: valueWords[0].bbox.x0,
      x1: valueWords[valueWords.length - 1].bbox.x1,
      y0: valueWords[0].bbox.y0,
      y1: valueWords[valueWords.length - 1].bbox.y1
    });
  }


  // compute shortest distance for cartesian product of keyWords and valueWords
  let inputsKeys = [];
  let inputsValues = [];
  const distances = [];
  let argMin = [];

  if (keyPoint === undefined || valuePoint === undefined) {


    if (keyPoint === undefined) {
      inputsKeys = keyWords.map((w) => computeBBoxCenter(w.bbox));
    } else {
      inputsKeys = [keyPoint];
    }
    if (valuePoint === undefined) {
      inputsValues = valueWords.map((w) => computeBBoxCenter(w.bbox));
    } else {
      inputsValues = [valuePoint];
    }

    inputsKeys.forEach((kb) => {
      inputsValues.forEach((vb) => {
        distances.push({ x: vb.x - kb.x, y: vb.y - kb.y });
      });
    });

    argMin = distances.reduce((acc, w, i) => acc !== null && d(distances[acc]) <= d(w) ? acc : i, null);
    keyPoint = inputsKeys[argMin / inputsKeys.length];
    valuePoint = inputsValues[argMin % inputsValues.length];
    distance = distances[argMin];
  } else {
    distance = d({ x: valuePoint.x - keyPoint.x, y: valuePoint.y - keyPoint.y })
  }

  return ({
    referencePoints: { key: keyPoint, value: valuePoint },
    distance: distance,
  });
};

/**
* find the kv pair based on their characteristic relative 
* positioning from a set of candidates for key bboxes 
* and value bboxes
* 
* @param {{
*   text: string,
*   bbox: { x0: Number, y0: Number, x1: Number, y1: Number },
*   direction: string,  
*   fontsize: Number,
* }[][]} keyCandidates
* @param {{
*   text: string,
*   bbox: { x0: Number, y0: Number, x1: Number, y1: Number },
*   direction: string,  
*   fontsize: Number,
* }[][]} valueCandidates
* @param {(dist: { x: Number, y: Number }) => Number} d
* @return { 
*  distanceDetails: { referencePoints: { key: { x: Number, y: Number }, value: { x: Number, y: Number } }, distance: { x: Number, y: Number } },
*  keyIndex: Number, 
*  valueIndex: Number 
* } 
* - keyIndex        - index in keyCandidates argument  
* - valueIndex      - index in valueCandidates argument
* - distanceDetails - distance object including reference points and distance in x and y
*/
const findKVPair = (keyCandidates, valueCandidates, d) => {

  const distances = [];

  if (keyCandidates.length === 0){
    throw new Error("'keyCandidates' has tb of length > 0")
  } 
  if (valueCandidates.length === 0){
    throw new Error("'valueCandidates' has tb of length > 0")
  }
  
  keyCandidates.forEach((kC) => {
    valueCandidates.forEach((vC) => {
      distances.push(computeKVDistance(kC, vC, d));
    });
  });
  
  return distances.map((dst, i) => ({
    distanceDetails: dst,
    keyIndex: Math.floor(i / valueCandidates.length),
    valueIndex: i % valueCandidates.length
  }));
};


export {
  invoiceSchema,
  regClean,
  regexify,
  extractAmountCurrency,
  extractDates,
  extractInvoiceNumbers,
  createDateRegex,
  extractValue,
  extractValues,
  checkContext,
  orderExtractedWordsByBbox,
  wordIndicesToString,
  findValues,
  extractKeys,
  isContiguous,
  computeBBoxCenter,
  computeKVDistance,
  findKVPair,
  findWordExtraction,
  extractBySchema,
  distanceMetricTBLR,
  localeMonths,
  currency_set
};
