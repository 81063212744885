const avgWordLength = (x) => { 
    const words = x.split(" ").map((w) => w.trim(/\s*/))
        .filter((w) => w.length > 0); 
    return words.reduce((acc, w) => acc + w.length, 0) / words.length; 
}
const checkExtraneousWhitespace = (x, lowerBoundAvgWordLength=2.0) => 
    avgWordLength(x) < lowerBoundAvgWordLength;
const fixExtraneousWhitespace = (x) => {
    const minWs = minimalWhitespaceLength(x);
    
    const rg = new RegExp(`(\\S)\\s{${minWs}}(?=\\S)`, 'g')
    return x.replaceAll(rg,"$1");
}
    

const stripRedundantWhitespace = (x) => x.replaceAll(/\s+/g, " ");

const minimalWhitespaceLength = (x) => {
    const counts = [...x.matchAll(/\s+/g)]
    .map((mt) => mt[0].length)
    .reduce((acc, ns) => ({...acc, ...(
        Object.keys(acc).includes(String(ns)) ? (
            { [ns]: acc[ns] + 1 }
        ) : (
            {[ns]: 1}
        )
    )}), {});
    const cts = Object.keys(counts).map((ct) => Number(ct));
    return cts.reduce((acc, ct) => acc === -1 || acc > ct ? ct : acc,  -1);
}

export { 
    avgWordLength, 
    checkExtraneousWhitespace, 
    fixExtraneousWhitespace, 
    stripRedundantWhitespace, 
    minimalWhitespaceLength
}