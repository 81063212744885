const project = "birdsvoice";
const ACCESS_TOKEN_KEY = `${project}-access-token`;
const REFRESH_TOKEN_KEY =  `${project}-refresh-token`;
const USER_KEY =  `${project}-user`;
const SCHEMA_KEY =  `${project}-schema`;
const ITEM_KEY =  `${project}-items`;


const makeBrowserStorage = (storage) => {
  function setAccessToken(t) {
    storage.setItem(ACCESS_TOKEN_KEY, t);
  }
  
  function getAccessToken() {
    return storage.getItem(ACCESS_TOKEN_KEY);
  }
  
  function setRefreshToken(t) {
    storage.setItem(REFRESH_TOKEN_KEY, t);
  }
  function setTokens(accessToken, refreshToken){
    setAccessToken(accessToken);
    setRefreshToken(refreshToken);
  }
  
  function getRefreshToken() {
    return storage.getItem(REFRESH_TOKEN_KEY);
  }
  function getTokens(){
    return [getAccessToken(), getRefreshToken()]
  }
  function hasAccessToken(){
    return getAccessToken() !== null;
  }
  function hasRefreshToken(){
    return getRefreshToken() !== null;
  }
  function hasTokens(){
    return hasAccessToken() && hasRefreshToken();
  }
  function removeAccessToken() {
    storage.removeItem(ACCESS_TOKEN_KEY);
  }
  function removeRefreshToken() {
    storage.removeItem(REFRESH_TOKEN_KEY);
  }
  function removeTokens(){
    removeAccessToken();
    removeRefreshToken(); 
  }
  function getUser(){
    const usr = storage.getItem(USER_KEY);
    return usr !== null && usr !== undefined ? JSON.parse(usr) : null;
  }
  function setUser(user){
    storage.setItem(USER_KEY, JSON.stringify(user));
  }
  function hasUserChanged(user){
    return JSON.stringify(user) !== storage.getItem(USER_KEY)
  }
  
  function removeUser(){
    storage.removeItem(USER_KEY);
  } 
  function getObject(objectId){
    const it = storage.getItem(ITEM_KEY);
    return it !== null && it !== undefined ? (
      (x) => x.length > 0 ? x[0] : null
    )(
      JSON.parse(it).filter((it) => it.id === objectId)
    ) : null;
  }
  function getObjects(){
    const it = storage.getItem(ITEM_KEY);
    return it !== null && it !== undefined ? JSON.parse(it) : null;
  }
  function hasObjects(){
    return getObjects() !== null;
  }  
  function addObject(object){
    if (!Object.keys(object).includes('id')){
      throw new Error("'object' has to include key 'id'");
    }
    const items = getObjects();
    storage.setItem(ITEM_KEY, JSON.stringify([...(items!== null ? items : []), object]));
  }
  function clearObjects(){
    storage.removeItem(USER_KEY);
  }
  function removeObject(objectId){
    const items = getObjects();
    storage.setItem(ITEM_KEY, JSON.stringify([...items.filter((it) => it.id !== objectId)]));
  }

  function getSchema(){
    const schema = storage.getItem(SCHEMA_KEY);
    return schema !== null && schema !== undefined ? JSON.parse(schema) : null;
  }
  function setSchema(schema){
    storage.setItem(SCHEMA_KEY, JSON.stringify(schema));
  }
  function hasSchema(){
    return getSchema() !== null;
  }    
  return ({
    setAccessToken,
    setRefreshToken,
    setTokens,
    getAccessToken,
    getRefreshToken,
    getTokens,
    hasAccessToken,
    hasRefreshToken,
    hasTokens,
    removeAccessToken,
    removeRefreshToken,
    removeTokens,
    getUser,
    setUser,
    removeUser,
    hasUserChanged,
    getObjects, 
    getObject,
    hasObjects, 
    addObject,
    clearObjects,
    removeObject,
    getSchema, 
    setSchema,
    hasSchema,
  });
}



const localstorage = makeBrowserStorage(localStorage) 
const sessionstorage = makeBrowserStorage(sessionStorage) 


export {
    localstorage,
    sessionstorage
};
