const extractRoutingSpecific = (routes) => routes.reduce((acc, route) => [
    ...acc, 
    Object.keys(route).reduce((acc, el) => ['path', 'element', 'exact'].includes(el) ? ({ ...acc, [el]: route[el]}) : acc, {})
], [])
  
const toNavigableRoutes = (routes, parentPath) => routes
    .filter((rt) => rt.element.type.name !== 'Navigate')
    .map((route) => {
        if (
        parentPath === undefined
        ||  ! route.path.startsWith(parentPath)
        ){
        throw new Error("'parentPath' not specified or one of the routes in 'routes' is not rooted at path 'parentPath'.")
        }
        const pt = (route.path.slice(parentPath.length));
        return ({
            path: route.path,
            name: `${pt.slice(0,1).toUpperCase()}${pt.slice(1)}`,
            icon: route.icon
        });
    }
);
  
  
const transformRoutes = ({ generalRoutes, loginRoute, settingsRoute }) => { 
        
    const navigableGeneralRoutes = toNavigableRoutes(generalRoutes, '/');
    let navigableLoginRoute = toNavigableRoutes([loginRoute], '/');
    let navigableSettingsRoute = toNavigableRoutes([settingsRoute], '/')

    if (navigableLoginRoute.length === 0) {
        throw new Error("'loginRoute' cannot be a redirect. Please specify the target location of the redirect instead.");
    }
    navigableLoginRoute = navigableLoginRoute[0];

    if (navigableSettingsRoute.length === 0) {
        throw new Error("'settingsRoute' cannot be a redirect. Please specify the target location of the redirect instead.");
    }
    navigableSettingsRoute = navigableSettingsRoute[0];

    return (
        {
        navigableGeneralRoutes,
        navigableLoginRoute,
        navigableSettingsRoute,
        }
    );
}

export { 
    extractRoutingSpecific,
    toNavigableRoutes, 
    transformRoutes
};