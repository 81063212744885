import { lazy, Suspense, createContext, useState, useContext, useEffect } from 'react';
import { useRoutes, Navigate } from 'react-router-dom';

import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';

import { baselightTheme } from "./theme/DefaultColors";


import DashboardIcon from '@mui/icons-material/Dashboard';
import LoginIcon from '@mui/icons-material/Login';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';



import { Auth0Provider } from '@auth0/auth0-react';

import {
  extractRoutingSpecific,
  transformRoutes
} from 'src/routing';


import { sessionstorage as store, localstorage as ls } from './browserstorage';
import { invoiceSchema, currency_set } from './extraction/extraction';

const theme = createTheme({ 
  ...baselightTheme,
});


const UserContext = createContext(null);

/**
 * user auth hook 
 * @returns {{
 *   info: {},
 *   token: string,
 *   isLoggedIn: bool,
 *   login: (x: { accessToken: string, idToken: string }) => {},
 *   logout: () => {},
 *   identities: { provider: string, id: string, token: string }[],
 *   integrations: { integration_type: string, integration_id: number, label: string, key: string, meta: {} }[], 
 * }} userAuth
 */
const useUser = () => {

  const context = useContext(UserContext);

  if (context === null){
    throw new Error("'useUser' may only be used within the 'App' component.");
  }

  
  const { info, token, isLoggedIn, login, logout } = context;

  return ({ info, token, isLoggedIn, login, logout }); 
}



const SchemaContext = createContext(null);

/**
 * 
 * @param {{
*  uniquenessConstraints: { message: string, constraint: string[] }[], 
*  invoice: {[x]:string: any}, 
*  existingInstances: {[x]:string: any}[]
* }} param0 
* @returns {string[]} empty array if unique otw. messages of uniqueness constraints that are not fulfilled
*/
const checkUniqueness = ({ schema, uniquenessConstraints, invoice, existingInstances }) => uniquenessConstraints.reduce((acc, uniquenessConstraint) => 
   existingInstances.filter((v) => uniquenessConstraint.constraint.filter((k) => schema[k]['type'] === 'date'&& !!invoice[k] && Object.prototype.toString.call(invoice[k]) === '[object Date]' ? invoice[k].getTime() === v[k].getTime() : invoice[k] === v[k] ).length === uniquenessConstraint.constraint.length).length > 0 ?
   [...acc, uniquenessConstraint.message] : acc
,[] );

const toSymbol = (x) => { const resCur = currency_set.filter(({ code }) => code === x); return resCur.length > 0 ? resCur[0].symbol : x; };


const castToSchemaTypes = (data, schema) => {
  const toSchemaType = (type, value) => {
    let castValue = null;
    if (value !== null && value !== undefined && (typeof value !=='string' || value.length > 0)) {
      switch(type){
        case 'date':
          castValue = new Date(value);
          break;
        case 'invoice_no': 
          castValue = value;
          break;
        case 'amount_cur':
          castValue = value;
          break;
        default:
          throw new Error("'type' not supported")
      }
    } else {
      castValue = value;
    }
    return castValue;
  }
  const cleanObj = Object.keys(data).reduce((acc, k) => ({ ...acc, [k]: Object.keys(schema).includes(k) ? toSchemaType(schema[k]['type'], data[k]) : data[k]}), {});
  return ({
    ...cleanObj,
    ...Object.keys(schema).reduce((acc,k) => !Object.keys(cleanObj).includes(k) ? ({ ...acc, [k]: null }): acc,{}),
  });
 
}

/**
 * schema hook 
 * @returns {{
 *   schema : { 
 *     [x: string]: { type: 'date'|'invoice_no'|'amount_cur', keys: string[]}
 *   },
*  idKey: string,
*  pKey: string,
*  uniquenessConstraints: { 
 *    constraint: string[],
 *    message: string
 *  }[],
 *  minimalSet: string[],
 *  updateSchema: ({ schema, idKey, uniquenessConstraints, minimalSet }) => void,
 * }} schema
 */
const useSchema = () => {

  const context = useContext(SchemaContext);

  if (context === null){
    throw new Error("'useSchema' may only be used within the 'App' component.");
  }

  
  const { schema, idKey, pKey, uniquenessConstraints, minimalSet, updateSchema } = context;

  return ({ schema, idKey, pKey, uniquenessConstraints, minimalSet, updateSchema }); 
}

const defaultSchema = Object.keys(invoiceSchema).reduce((acc, k) => (
  { ...acc, [k.toLowerCase()]: invoiceSchema[k] }
), {});

const defaultUniquenessConstraints = [
  { 
    message: "an invoice with the same 'invoice_number' and 'invoice_creation_date' exists already.", 
    constraint: ['invoice_number', 'invoice_creation_date']
  }
];

const defaultMinimalSet = ['invoice_number', 'invoice_creation_date', 'amount_total', 'currency'];

const Loadable = (Component) => (props) =>
(
  <Suspense fallback={<div><p>Component loading ...</p></div>} >
    <Component {...props} />
  </Suspense>
);


const Sidebar = Loadable(lazy(() => import('./apppage/sidebar/Sidebar')));
const Login = Loadable(lazy(() => import('./pages/Login')));
const Settings = Loadable(lazy(() => import('./pages/Settings')));

const Extract = Loadable(lazy(() => import('./pages/extract/Extract')));
const Dashboard = Loadable(lazy(() => import('./pages/dashboard/Dashboard')));




function App() {

  const [token, setTok] = useState(store.hasAccessToken() !== null ? store.getAccessToken() : null); // ) localstorage.getRefreshToken());

  const setToken = (token) =>  { 
    if (! token){ 
      throw new Error('token null or undefined.'); 
    } 
    setTok(token); 
    store.setAccessToken(token)
  }
  const removeToken = () =>  {
    setTok(null);
    store.removeAccessToken(null);
  }

  const [user, setUsr] = useState(store.hasAccessToken() ? store.getUser() : null);
  const setUser = (u) => { setUsr(u); store.setUser(u); };
  const removeUser = () => {
    setUsr(null);
    store.removeUser();
  }

  useEffect(() => {
  }, [token]);



  // schema 
  const [schema, setSchema] = useState(ls.hasSchema() ? ls.getSchema() : { 
    schema: defaultSchema, 
    idKey: 'invoice_number',
    pKey: 'invoice_id', 
    uniquenessConstraints: defaultUniquenessConstraints,
    minimalSet: defaultMinimalSet,
  });

  const updateSchema = ({ schema, idKey, pKey, uniquenessConstraints, minimalSet }) => {
    setSchema({schema, idKey, pKey, uniquenessConstraints, minimalSet });
    ls.setSchema({ schema, idKey, pKey, uniquenessConstraints, minimalSet });
  };


  // routes
  const settingsRoute = { 
    path: '/settings', 
    exact: true,
    element: <Settings />,    
    icon: SettingsSuggestIcon
  };

  const generalRoutes = [
    { path: '/', exact: true, element: <Navigate to='/extract' /> },
    { 
      path: '/dashboard', 
      exact: true, 
      element: <Dashboard />, 
      icon: DashboardIcon 
    },
    { 
      path: '/extract', 
      exact: true, 
      element: <Extract />, 
      icon: DashboardIcon 
    }
  ];
    
  const loginRoute = { 
    path: '/login', 
    exact: true,
    element: <Login />,
    icon: LoginIcon
  };

  const {
    navigableGeneralRoutes,
    navigableLoginRoute,
    navigableSettingsRoute,
  } = transformRoutes({ generalRoutes, loginRoute, settingsRoute });
  

  const routing = useRoutes([
    {
      path: '/',
      element: <Sidebar {...{ navigableGeneralRoutes, navigableSettingsRoute, navigableLoginRoute }} />,
      children: extractRoutingSpecific([...generalRoutes, settingsRoute, loginRoute]),
    }
  ]);


  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <UserContext.Provider value={{ 
        info:user,
        token, 
        isLoggedIn: !! token, 
        login: ({ accessToken, idToken }) => { setToken(accessToken); setUser(idToken); }, 
        logout: () => { removeToken(); removeUser(); },
      }} >
          <Auth0Provider
            domain={`${import.meta.env.AUTH0_DOMAIN}`}
            clientId={`${import.meta.env.AUTH0_CLIENT_ID}`}
            useRefreshTokens={true}
            authorizationParams={{ redirect_uri: window.location.origin }}
          >
              <SchemaContext.Provider value={{ ...schema, updateSchema }} >
                  { routing }
              </SchemaContext.Provider>
          </Auth0Provider>
      </UserContext.Provider>
    </ThemeProvider>
  );
}

export default App;
export {
  useUser,
  useSchema,
  checkUniqueness,
  castToSchemaTypes, 
  toSymbol
};